import Error from '@/pages/error';

import { getInitialProps } from '@/lib/graphql/api';
import { PageQuery } from '@/lib/graphql/querys/PageQuery';
import { pagePropsServerTransformer } from '@/lib/graphql/transformers';

function Page404() {
  return <Error error={404} />;
}

export async function getStaticProps() {
  return await pagePropsServerTransformer({
    staticProps: await getInitialProps(PageQuery(), { slug: '404-2' }),
    type: 'Page',
    path: '404-2',
  });
}

export default Page404;
